<template>
  <div>
    <b-sidebar
      id="sidebar-details"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Details</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2">
        <b-row class="">
          <b-col md="12" class="mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="48"
                  :src="currentItem.student.picture"
                  variant="light-primary"
                />
              </template>

              <b-badge variant="light-primary">
                {{ currentItem.student.name }}
              </b-badge>

              <span
                class="font-weight-bold d-block text-nowrap"
                style="margin: 4px 0px"
              >
                <b-badge variant="light-primary">
                  F/N: {{ currentItem.student.father_name }}
                </b-badge>
              </span>

              <small class="text-muted">
                <b-badge variant="light-primary">
                  GR - {{ currentItem.student.grNo }}
                </b-badge>
                <b-badge variant="light-primary" style="margin-inline: 10px">
                  {{ currentItem.student.meta_class }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
          <b-col md="12" class="mt-1">
            <!-- <h6 class="price-title mb-1">Price Details</h6> -->
            <ul class="list-unstyled">
              <li
                class="d-flex justify-content-between mb-1"
                v-for="(item, ind) in currentDetails"
                :key="item.id"
              >
                <div class="">
                  <span class=""
                    >{{ getProdName(item.productID) }} ({{ item.qty }})</span
                  >
                </div>
                <div class="">Rs.{{ item.price }}</div>
              </li>
            </ul>
            <hr />
            <ul class="list-unstyled">
              <li class="d-flex justify-content-between mb-1">
                <div class="font-weight-bolder">Total</div>
                <div class="font-weight-bolder">Rs.{{ currentItem.total }}</div>
              </li>
            </ul>
          </b-col>
        </b-row>
      </b-form>

      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="mt-1">
        <b-col
          class="mt-lg-0 mt-md-0 mt-sm-1 mt-1 d-flex align-items-center"
          md="12"
          cols="12"
        >
          <b-form-group class="mb-0 w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(date)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.value).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>
        <template #cell(student)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                :src="data.value.picture"
                variant="light-primary"
              />
            </template>

            <b-badge variant="light-primary">
              {{ data.value.name }}
            </b-badge>

            <span
              class="font-weight-bold d-block text-nowrap"
              style="margin: 4px 0px"
            >
              <b-badge variant="light-primary">
                F/N: {{ data.value.father_name }}
              </b-badge>
            </span>

            <small class="text-muted">
              <b-badge variant="light-primary">
                GR - {{ data.value.grNo }}
              </b-badge>
              <b-badge variant="light-primary" style="margin-inline: 10px">
                {{ data.value.meta_class }}
              </b-badge>
            </small>
          </b-media>
        </template>

        <template #cell(total)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>
        <template #cell(sellingPrice)="data">
          <b-badge variant="light-primary">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- <template #head(actions)="data">
          <div class="ml-2">
            <span>{{ data.label }}</span>
          </div>
        </template> -->

        <template #cell(actions)="data">
          <div class="">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="View Details"
              class="btn-icon"
              @click="open(data.item)"
            >
              <feather-icon icon="EyeIcon" class="" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.right
              title="Reprint"
              class="btn-icon ml-50"
            >
              <feather-icon icon="PrinterIcon" class="" />
            </b-button>
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      currentItem: {
        student: {},
      },
      currentDetails: [],
      visibility: false,
      items: [
        {
          id: 1,
          date: "2023-11-10",
          total: 210,
          student: {
            name: "ali",
            father_name: "ahmed",
            grNo: "99",
            meta_class: "VI - Boys",
          },
          detailID: [1, 2],
        },
        {
          id: 2,
          date: "2023-11-12",
          total: 500,
          student: {
            name: "Athar",
            father_name: "Akbar",
            grNo: "11225579",
            meta_class: "VIII - Boys",
          },
          detailID: [3],
        },
        {
          id: 3,
          date: "2024-01-06",
          total: 120,
          student: {
            name: "ali",
            father_name: "ahmed",
            grNo: "99",
            meta_class: "VI - Boys",
          },
          detailID: [4, 5],
        },
      ],
      details: [
        {
          id: 1,
          productID: 2,
          qty: 1,
          price: 150,
          sellingPrice: 150,
        },
        {
          id: 2,
          productID: 3,
          qty: 4,
          price: 60,
          sellingPrice: 15,
        },
        {
          id: 3,
          productID: 12,
          qty: 2,
          price: 500,
          sellingPrice: 15,
        },
        {
          id: 4,
          productID: 5,
          qty: 4,
          price: 100,
          sellingPrice: 25,
        },
        {
          id: 5,
          productID: 1,
          qty: 1,
          price: 110,
          sellingPrice: 110,
        },
      ],
      fields: [
        { label: "Slip No#", key: "id" },
        { label: "student", key: "student" },
        { label: "date", key: "date" },
        { label: "total", key: "total" },
        { key: "actions", label: "actions" },
      ],
      perPage: 30,
      currentPage: 1,
      searchQuery: "",
      products: [
        {
          id: 1,
          name: "Uniform",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 120,
          purchasePrice: 4500,
          sellingPrice: 110,
        },
        {
          id: 2,
          name: "Shoes",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 60,
          purchasePrice: 2000,
          sellingPrice: 150,
        },
        {
          id: 3,
          name: "Crayons",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 10,
          purchasePrice: 100,
          sellingPrice: 15,
        },
        {
          id: 4,
          name: "Color Paper",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 100,
          purchasePrice: 4000,
          sellingPrice: 12,
        },
        {
          id: 5,
          name: "Pens Set",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 50,
          purchasePrice: 1500,
          sellingPrice: 25,
        },
        {
          id: 6,
          name: "Notebook Pack",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 80,
          purchasePrice: 3000,
          sellingPrice: 20,
        },
        {
          id: 7,
          name: "Sticky Notes Bundle",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 120,
          purchasePrice: 2000,
          sellingPrice: 15,
        },
        {
          id: 8,
          name: "Markers Assortment",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 60,
          purchasePrice: 2500,
          sellingPrice: 18,
        },
        {
          id: 9,
          name: "Eraser Set",
          warehouseID: 1,
          categoryID: 2,
          supplierID: 2,
          quantity: 200,
          purchasePrice: 1000,
          sellingPrice: 8,
        },
        {
          id: 10,
          name: "School Sweaters",
          warehouseID: 1,
          categoryID: 1,
          supplierID: 1,
          quantity: 50,
          purchasePrice: 8000,
          sellingPrice: 200,
        },
        {
          id: 12,
          name: "Laptop Cooling Pad",
          warehouseID: 1,
          categoryID: 3,
          supplierID: 3,
          quantity: 50,
          purchasePrice: 11000,
          sellingPrice: 250,
        },
        {
          id: 13,
          name: "Wireless Microphones",
          warehouseID: 1,
          categoryID: 3,
          supplierID: 3,
          quantity: 0,
          purchasePrice: 2500,
          sellingPrice: 180,
        },
      ],
    };
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    getProdName(id) {
      let obj = this.products.find((elem) => elem.id == id);
      return obj ? obj.name : "";
    },

    open(item) {
      this.currentItem = item;
      this.currentDetails = [];
      item.detailID.forEach((el) => {
        let obj = this.details.find((elem) => elem.id == el);
        if (obj) this.currentDetails.push(obj);
      });
      this.visibility = true;
    },
  },
};
</script>
<style lang=""></style>
